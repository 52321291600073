import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCalendar, BiCommentDetail, BiMap, BiTimeFive } from 'react-icons/bi'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useParams } from 'react-router-dom'

import {
	Icon,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'
import { format, formatDistanceToNow, Locale } from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'

import SelectColor from 'modules/common/components/hexagon-icon'
import {
	IconGCalendar,
	IconStPointConversation
} from 'modules/common/components/icons'
import { formatToLocalDateTime } from 'modules/common/utils/format-to-local-date-time'
import { GCalendar } from 'modules/common/utils/gcalendar'
import { hasUrl } from 'modules/common/validators'
import { useJourney } from 'modules/journeys/hooks'
import { ButtonSharePoint } from 'modules/map/components/button-share-point'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { useConversationPoint } from '../../hooks'
import { useConversationChatStore } from '../../store/chat-store'
import { Chat } from '../chat/chat'
import { CommentInputField } from '../chat/comment-input-field'
import ConversationOptions from '../options/conversation-options'

const TimeToMeeting: React.FC<{
	icon: React.FC
	date: string
	language: Locale
}> = ({ icon, date, language }) => {
	const dt = date ? new Date(formatToLocalDateTime(date)) : new Date()
	const timeLeft = formatDistanceToNow(dt, { locale: language })
	const { t } = useTranslation()
	return (
		<Stack direction='row' align='center'>
			<Icon as={icon} color='gray.400' fontSize='2xl' />
			<Text color='gray.400' fontSize='sm' fontWeight='semibold'>
				{t('map:points.conversation.view.timeLeft', { timeLeft })}
			</Text>
		</Stack>
	)
}

const Info: React.FC<{
	text: string
	icon: React.FC
	color?: string
	children?: React.ReactNode
}> = (props) => (
	<Stack direction='row' align='top' mb={2}>
		<Icon
			as={props.icon}
			color={props.color ? props.color : 'gray.300'}
			fontSize='2xl'
		/>
		<Text
			color={props.color ? props.color : 'gray.300'}
			fontSize='sm'
			fontWeight='semibold'
		>
			{props.text}
		</Text>
		{props.children}
	</Stack>
)

const InfoText: React.FC<PropsWithChildren> = ({ children }) => {
	const responsiveFontSize = useBreakpointValue({ base: 'xs', md: 'sm' })
	return (
		<Text
			mt={['2px', 0]}
			fontWeight='semibold'
			fontSize={responsiveFontSize}
			color='gray.500'
		>
			{children}
		</Text>
	)
}

export const ContentConversation: React.FC<{ pointId: string }> = ({
	pointId
}) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false })

	const Point = useConversationPoint(pointId)
	const { data: point } = Point

	const { addComment } = useConversationChatStore()

	const calendarLink = GCalendar.getGCalendarPath({
		location: `${point?.meeting_place}`,
		details: `${point?.description}`,
		dates: GCalendar.parseDate(point?.opening_date || ''),
		text: t('map:points.conversation.create.title')
	})

	const responsiveFontSize = useBreakpointValue({ base: 'xs', md: 'sm' })

	const { id: journeyId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const { user: currUser } = useMe()
	const { data: users = [] } = useJourney(journeyId as string, {
		select: (journey) => journey.users
	})

	const {
		actions: { updatePoint },
		state: { selectedPoint }
	} = useMapStore()

	const toast = useToast()

	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))

	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'check-point',
		pointId: selectedPoint?.id || ''
	})

	const language = currUser?.language === 'EN_US' ? enUS : ptBR
	const languageDateFormat =
		currUser?.language === 'EN_US' ? 'MM/dd/yy' : 'dd/MM/yy'
	const languageHourFormat = currUser?.language === 'EN_US' ? 'H:mm a' : 'HH:mm'

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('checkpoint.delete'))

	const handleAddComment = (text: string) => {
		addComment(pointId, text)
	}

	const resolveDateToLocalTime = (date: string) => {
		if (date) {
			const dateWithTimeZone = new Date(formatToLocalDateTime(date))
			const openingDate = format(dateWithTimeZone, languageDateFormat)
			const time = format(dateWithTimeZone, languageHourFormat)

			const openingDateAndTime = t('map:points.conversation.view.openingDate', {
				openingDate,
				time
			})

			return openingDateAndTime
		}
		return null
	}

	const handleToggleVisibility = () => {
		toast.closeAll()

		if (selectedPoint) {
			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint(
						{
							...selectedPoint,
							visible: !selectedPoint.visible
						},
						true
					)
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					if (isMobile) {
						toast({
							title: selectedPoint?.visible
								? t('map:points.action.state.tooltip-mobile')
								: t('map:points.action.visible.success'),
							status: 'success'
						})
					} else {
						toast({
							title: selectedPoint.visible
								? t('map:points.action.hidden.success')
								: t('map:points.action.visible.success'),
							description: selectedPoint.visible
								? t('map:points.action.state.tooltip-alt')
								: '',
							status: 'success'
						})
					}
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<ModalContent>
			{!isMobile && hasPrivileges && (
				<>
					<Menu>
						<MenuButton
							as={IconButton}
							aria-label={
								selectedPoint?.visible
									? t('map:points.action.hidden.title')
									: t('map:points.action.visible.title')
							}
							size='sm'
							position='absolute'
							top={2}
							mt='13.5px'
							right='85px'
							icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
							fontSize='xl'
							variant='ghost'
							color='gray.400'
						/>
						<MenuList>
							<MenuItem
								borderRadius='md'
								fontSize='md'
								onClick={handleToggleVisibility}
							>
								{selectedPoint?.visible
									? t('map:points.action.hidden.title')
									: t('map:points.action.visible.title')}
							</MenuItem>
						</MenuList>
					</Menu>
				</>
			)}
			<ModalCloseButton color='gray.400' mt={3.5} mr={7} />
			<ModalHeader pt='3.5' mr={3.5}>
				<Stack direction='row' align='center' justify='space-between' mt={12}>
					<Stack direction='row' align='center'>
						<SelectColor
							visible={selectedPoint?.visible}
							Icon={IconStPointConversation}
							color='black.500'
						/>
						<Text fontSize='xl' mt={2}>
							{t('map:points.conversation.create.title')}
						</Text>
					</Stack>
					<ButtonSharePoint pointType='conversation' />
				</Stack>
			</ModalHeader>
			{point && (
				<>
					<ModalBody>
						<Stack px={2} py={3}>
							<Stack
								spacing={4}
								direction='row'
								align='center'
								justifyContent='space-between'
							>
								<Info
									text={t('map:points.conversation.create.date.label')}
									icon={BiCalendar}
								>
									<InfoText>
										{resolveDateToLocalTime(point.opening_date)}
									</InfoText>
									{point.status === 'SCHEDULED' ? (
										<Tag
											fontWeight='bold'
											fontSize='xs'
											bg='gray.300'
											color='white'
										>
											{t('map:points.conversation.view.status.scheduled')}
										</Tag>
									) : null}
								</Info>
								{hasPrivileges && mapId && (
									<ConversationOptions
										conversationPoint={point}
										isScheduled={point.status === 'SCHEDULED'}
										projectId={journeyId}
										mapId={mapId}
										handleToggleVisibility={handleToggleVisibility}
										selectedPoint={selectedPoint}
									/>
								)}
							</Stack>
							<Info
								text={t('map:points.conversation.create.subject.viewLabel')}
								icon={BiCommentDetail}
							>
								<InfoText>{point.description}</InfoText>
							</Info>
							<Info
								text={t('map:points.conversation.create.place.label')}
								icon={BiMap}
							>
								{hasUrl(point.meeting_place) ? (
									<Link
										as='a'
										href={point.meeting_place}
										target='_blank'
										rel='noopener noreferrer'
										color='blue.500'
										fontSize={responsiveFontSize}
										whiteSpace='normal'
										textOverflow='ellipsis'
										overflow='hidden'
									>
										{point.meeting_place}
									</Link>
								) : (
									<InfoText>{point.meeting_place}</InfoText>
								)}
							</Info>
						</Stack>
						{point.status === 'SCHEDULED' && (
							<Stack direction='row' align='center' ml={2} mb={6}>
								<Icon as={IconGCalendar} fontSize='2xl' />
								<Link
									as='a'
									href={calendarLink}
									target='_blank'
									rel='noopener noreferrer'
									color='blue.500'
									fontSize={responsiveFontSize}
									whiteSpace='normal'
									textOverflow='ellipsis'
									overflow='hidden'
									fontWeight='medium'
								>
									{t('map:points.conversation.addToCalendar')}{' '}
									<strong>Google Calendar</strong>
								</Link>
							</Stack>
						)}
						{point.status === 'SCHEDULED' ? (
							<Stack
								direction='row'
								justifyContent='center'
								mt={2}
								p={3}
								bg='gray.100'
							>
								<TimeToMeeting
									language={language}
									date={point.opening_date}
									icon={BiTimeFive}
								/>
							</Stack>
						) : null}
						<Chat />
					</ModalBody>
					<ModalFooter p={0}>
						<CommentInputField
							cantComment={point.status === 'SCHEDULED'}
							handleComment={handleAddComment}
							layoutProps={{
								px: 8,
								py: 4
							}}
						/>
					</ModalFooter>
				</>
			)}
		</ModalContent>
	)
}
