import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FiMoreVertical } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import {
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import DeleteModal from 'modules/common/components/modals/delete-modal'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'

import { useMapStore } from '../../map-canvas/store'
import { pointChannel } from '../../point/point_channel'

type Props = {
	onDelete: () => void
	onEdit?: () => void
	pointDate: string
}

export const OptionsNoticePoint: FC<Props> = (props) => {
	const { id: journeyId = '', mapId = '' } = useParams<{
		questionId: string | undefined
		id: string | undefined
		mapId: string | undefined
	}>()
	const {
		state: { selectedPoint },
		actions: { updatePoint }
	} = useMapStore()
	const { t } = useTranslation()

	const toast = useToast()
	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'notice-point',
		pointId: selectedPoint?.id || ''
	})
	const hasPrivileges = useJourneyPrivileges()

	const { onDelete, onEdit, pointDate } = props
	const deleteDisClosure = useDisclosure()
	const isMobile = useBreakpointValue({ base: true, md: false })

	const handleToggleVisibility = async () => {
		if (selectedPoint) {
			toast.closeAll()

			updatePointVisibility(!selectedPoint?.visible, {
				onSuccess: () => {
					updatePoint(
						{ ...selectedPoint, visible: !selectedPoint?.visible },
						true
					)
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint?.visible
					})
					if (isMobile) {
						toast({
							title: selectedPoint?.visible
								? t('map:points.action.state.tooltip-mobile')
								: t('map:points.action.visible.success'),
							status: 'success'
						})
					} else {
						toast({
							title: selectedPoint.visible
								? t('map:points.action.hidden.success')
								: t('map:points.action.visible.success'),
							description: selectedPoint.visible
								? t('map:points.action.state.tooltip-alt')
								: '',
							status: 'success'
						})
					}
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<>
			<Menu isLazy placement='bottom-end' closeOnBlur>
				<MenuButton
					as={IconButton}
					aria-label={t('buttons:more')}
					variant='ghost'
					color='gray.400'
					isRound
					icon={<Icon as={FiMoreVertical} fontSize='xl' />}
					_active={{ bgColor: 'gray.100' }}
					position='relative'
					zIndex={99}
				/>
				<MenuList>
					{isMobile && hasPrivileges && (
						<MenuItem
							borderRadius='md'
							fontSize='md'
							onClick={handleToggleVisibility}
							mb={2}
						>
							{selectedPoint?.visible
								? t('map:points.action.hidden.title')
								: t('map:points.action.visible.title')}
						</MenuItem>
					)}
					<MenuItem borderRadius='md' onClick={onEdit}>
						{t('buttons:edit')}
					</MenuItem>
					<MenuItem borderRadius='md' onClick={deleteDisClosure.onOpen}>
						{t('buttons:delete')}
					</MenuItem>
				</MenuList>
			</Menu>

			{deleteDisClosure.isOpen && (
				<DeleteModal
					title={t('map:points:notice:delete:title')}
					deleteText={t('buttons:delete')}
					onDelete={() => onDelete()}
					{...deleteDisClosure}
				>
					<Trans
						i18nKey='map:points:notice:delete:body'
						values={{ date: pointDate }}
						components={[<strong />]}
					/>
				</DeleteModal>
			)}
		</>
	)
}
