import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconToolBox: React.FC = (props: IconProps) => (
	<IconBase width='22' height='20' viewBox='0 0 22 20' fill='none' {...props}>
		<path
			d='M7.5 9V12M14.5 9V12M1.75 10.5H20.25M1.75 8C1.75 7.20435 2.06607 6.44129 2.62868 5.87868C3.19129 5.31607 3.95435 5 4.75 5H17.25C18.0456 5 18.8087 5.31607 19.3713 5.87868C19.9339 6.44129 20.25 7.20435 20.25 8V15.5C20.25 16.2956 19.9339 17.0587 19.3713 17.6213C18.8087 18.1839 18.0456 18.5 17.25 18.5H4.75C3.95435 18.5 3.19129 18.1839 2.62868 17.6213C2.06607 17.0587 1.75 16.2956 1.75 15.5V8ZM7 3.5C7 2.96957 7.21071 2.46086 7.58579 2.08579C7.96086 1.71071 8.46957 1.5 9 1.5H13C13.5304 1.5 14.0391 1.71071 14.4142 2.08579C14.7893 2.46086 15 2.96957 15 3.5V5H7V3.5Z'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</IconBase>
)
