import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#BB2A36',
		light: '#E4AAAF'
	},

	secondary: {
		dark: '#000000',
		light: '#E1E1E1'
	}
}

export default themeColors
