import produce from 'immer'
import create from 'zustand'

import { Point, PointMap } from '../types'
import {
	activateNeighbors,
	disableNeighbors,
	hasFilledNeighbors
} from '../utils/neighbors-utils'

type MapState = {
	state: {
		map: PointMap
		points: Point[]
		selectedPoint: Point | null
		skipNeighbors: boolean
	}
	actions: {
		setMap: (map: PointMap, skipNeighbors?: boolean) => void
		clearMap: () => void
		setPoint: (point: Point) => void
		setPoints: (points: Point[]) => void
		addPoint: (point: Point) => void
		updatePoint: (point: Point, changeState: boolean) => void
		removePoint: (point: Point) => void
		clearSelected: () => void
		selectPointById: (id: string) => void
		getPointById: (id: string) => Point
	}
}

export const useMapStore = create<MapState>((set, get) => {
	const setState = (fn: (state: MapState) => void) => set(produce(fn))

	return {
		state: {
			map: {},
			points: [],
			selectedPoint: null,
			skipNeighbors: true
		},

		actions: {
			setMap(map: PointMap, skipNeighbors = true) {
				setState(({ state }) => {
					state.map = map
					state.skipNeighbors = skipNeighbors
				})
			},
			clearMap() {
				setState(({ state }) => {
					state.map = {}
				})
			},

			setPoint(point: Point) {
				setState(({ state }) => {
					state.selectedPoint = point
				})
			},

			setPoints(points: Point[]) {
				setState(({ state }) => {
					state.points = points
				})
			},

			clearSelected() {
				setState(({ state }) => {
					state.selectedPoint = null
				})
			},

			addPoint(point: Point) {
				setState(({ state }) => {
					const { position } = point
					const { row, col } = position
					state.map[row][col] = point
					state.points.push(point)
					if (!state.skipNeighbors) {
						activateNeighbors(state.map, position)
					}
				})
			},

			updatePoint(point: Point, changeState: boolean = true) {
				setState(({ state }) => {
					const { position } = point
					const { row, col } = position
					state.map[row][col] = point
					if (changeState) {
						state.selectedPoint = point
					}
				})
			},

			removePoint(point: Point) {
				setState(({ state }) => {
					const { position } = point
					const { row, col } = position

					if (!state.skipNeighbors && hasFilledNeighbors(state.map, position)) {
						state.map[row][col].point_type = 'ADD'
					} else {
						state.map[row][col].point_type = 'EMPTY'
					}

					state.points = state.points.filter(({ id }) => id !== point.id)

					if (!state.points.length) {
						state.map[0][0].point_type = 'ADD'
					}

					if (!state.skipNeighbors) {
						disableNeighbors(state.map, position)
					}
				})
			},

			selectPointById(id: string) {
				setState(({ state }) => {
					const { points } = state
					const pointToSelect = points.find((point) => point.id === id)
					if (pointToSelect) {
						state.selectedPoint = pointToSelect
					}
				})
			},

			getPointById(id: string) {
				return get().state.points.find((point) => point.id === id) as Point
			}
		}
	}
})
