import { TenantColors } from '../types'
import inovabraCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const inovabraColors: TenantColors = {
	...inovabraCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default inovabraColors
