import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useParams } from 'react-router-dom'

import {
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	Stack,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStPointConvergence } from 'modules/common/components/icons'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { ButtonSharePoint } from 'modules/map/components/button-share-point'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { useConvergencePoint, useConvergencePointAnswer } from '../../hooks'
import { useConvergenceStore } from '../../store'
import { Answer } from './answer/answer'
import { ConfigPoint } from './edition-block/config-convergence-point'
import { Reply } from './reply/reply'
import { Result } from './result/result'

type Props = {
	pointId: string
}

export const ContentConvergence: FC<Props> = ({ pointId }) => {
	const {
		t,
		i18n: { language }
	} = useTranslation()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const setClosingDate = useConvergenceStore((state) => state.updateTime)
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'convergence-point',
		pointId
	})
	const {
		actions: { updatePoint },
		state: { selectedPoint }
	} = useMapStore()
	const toast = useToast()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const Point = useConvergencePoint(pointId)
	const MyAnswer = useConvergencePointAnswer(pointId)
	const hasPrivileges = useJourneyPrivileges()
	const [isEditingAnswer, setIsEditingAnswer] = useState(false)

	const { data: point } = Point

	useEffect(() => {
		if (point) {
			setClosingDate(
				point.effective_closing_date || point.closing_date,
				language
			)
		}
	}, [point, language, setClosingDate])

	if (Point.isLoading && MyAnswer.isLoading) return null
	if (!point) return null

	const isClosed = point.status === 'CLOSED'

	const Content = () => {
		if (isClosed) {
			const myAnswers = MyAnswer?.data?.answers || []
			return <Result point={point} myAnswer={myAnswers} />
		}

		if (MyAnswer.data && !isEditingAnswer) {
			return (
				<Answer
					myAnswer={MyAnswer.data.answers}
					setIsEditingAnswer={setIsEditingAnswer}
				/>
			)
		}

		if (isEditingAnswer && MyAnswer.data) {
			return (
				<Reply
					point={point}
					myAnswer={MyAnswer.data.answers}
					setIsEditingAnswer={setIsEditingAnswer}
				/>
			)
		}

		return <Reply point={point} />
	}

	const handleToggleVisibility = () => {
		toast.closeAll()

		if (selectedPoint) {
			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint(
						{
							...selectedPoint,
							visible: !selectedPoint.visible
						},
						true
					)
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					if (isMobile) {
						toast({
							title: selectedPoint?.visible
								? t('map:points.action.state.tooltip-mobile')
								: t('map:points.action.visible.success'),
							status: 'success'
						})
					} else {
						toast({
							title: selectedPoint.visible
								? t('map:points.action.hidden.success')
								: t('map:points.action.visible.success'),
							description: selectedPoint.visible
								? t('map:points.action.state.tooltip-alt')
								: '',
							status: 'success'
						})
					}
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<ModalContent>
			<ModalCloseButton
				color='gray.400'
				mt={selectedPoint?.visible ? [4, 8] : ['33px', '49px']}
				mr={[0, 4]}
			/>
			<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
				<Stack direction='row' alignItems='center' justify='space-between'>
					<Stack direction='row' alignItems='center'>
						<SelectColor
							visible={selectedPoint?.visible}
							Icon={IconStPointConvergence}
							color='purple.500'
							mb={4}
						/>
						<Text
							fontWeight='semibold'
							fontSize={['lg', 'xl']}
							mt={selectedPoint?.visible ? 0 : [-2, -4]}
						>
							{t('map:points.convergence.create.title')}
						</Text>
					</Stack>
					<Flex
						pr={isMobile ? 6 : 10}
						justifyContent={'center'}
						alignItems={'center'}
					>
						{!isMobile && hasPrivileges && (
							<>
								<Menu>
									<MenuButton
										as={IconButton}
										aria-label={
											selectedPoint?.visible
												? t('map:points.action.hidden.title')
												: t('map:points.action.visible.title')
										}
										size='sm'
										icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
										fontSize='xl'
										variant='ghost'
										color='gray.400'
									/>
									<MenuList>
										<MenuItem
											borderRadius='md'
											fontSize='md'
											onClick={handleToggleVisibility}
										>
											{selectedPoint?.visible
												? t('map:points.action.hidden.title')
												: t('map:points.action.visible.title')}
										</MenuItem>
									</MenuList>
								</Menu>
							</>
						)}
						<ButtonSharePoint pointType='convergence' />
					</Flex>
				</Stack>
			</ModalHeader>
			<ConfigPoint
				point={point}
				handleToggleVisibility={handleToggleVisibility}
			/>
			<Content />
		</ModalContent>
	)
}
