import { fetch } from 'config'

type CodeResponse = {
	id: string
	qr_code: string
}

type CodeRequest = {
	credit_amount: number
	metadata: {
		whitelabel: string
	} | null
}

export const generateCode = ({ credit_amount, metadata }: CodeRequest) => {
	return fetch<CodeResponse>({
		url: 'billing/v1/purchase/credits/pix/generate',
		method: 'POST',
		data: { credit_amount, metadata }
	}).then(({ data }) => data)
}
