import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import {
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalCloseButton,
	ModalContent,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { FullscreenBox } from 'modules/common/components'
import Loading from 'modules/common/components/loading'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import {
	DivergenceProvider,
	useDivergenceStore
} from 'modules/map/store/divergence-store'
import useCommentsStore, {
	Comments
} from 'modules/map/store/use-comments-store'
import useReferencesStore from 'modules/map/store/use-references-store'

import { ChatMobile } from '../chat/chat-mobile'
import { AboutToolRightViewMobile } from './about-tool-right-view-mobile'
import { RightDivergenceView } from './divergence-main-view'
import { LeftDivergenceView } from './divergence-sidebar'
import { LeftDivergenceViewMobile } from './divergence-sidebar-mobile'
import { useDivergencePoint } from './hooks/use-divergence-point'
import { QuestionAnalysis } from './summary/question-analysis-view'

type Props = {
	pointId: string
}

export const ContentDivergencePoint: React.FC<Props> = ({ pointId }) => {
	const { id: journeyId = '', mapId = '' } = useParams<{
		questionId: string | undefined
		id: string | undefined
		mapId: string | undefined
	}>()
	const {
		state: { selectedPoint },
		actions: { updatePoint }
	} = useMapStore()
	const {
		fetchQuestionsInfo,
		addCommentState,
		updateCommentState,
		deleteCommentState,
		fetchedQuestionsIds
	} = useCommentsStore()
	const { fetchReferencecsAndLinks } = useReferencesStore()
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { subscribe } = useSocket(
		divergencyPointChannel({ projectId: journeyId })
	)
	const { t } = useTranslation()
	const toast = useToast()
	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))
	const { data, isSuccess, isLoading } = useDivergencePoint(pointId)
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'divergence-point',
		pointId: selectedPoint?.id || ''
	})
	const hasPrivileges = useJourneyPrivileges()

	useEffect(() => {
		if (selectedPoint) {
			subscribe<Comments>('ADD', (data) => {
				if (data.divergence_point_id === selectedPoint.id) {
					addCommentState(data)
				}
			})
			subscribe<Comments>('EDIT', (data) => {
				if (data.divergence_point_id === selectedPoint.id) {
					updateCommentState(data)
				}
			})
			subscribe<Comments>('DELETE', (data) => {
				if (data.divergence_point_id === selectedPoint.id) {
					deleteCommentState(data)
				}
			})
			subscribe<Comments>('ADD_AGREEMENT', (data) => {
				if (data.divergence_point_id === selectedPoint.id) {
					updateCommentState(data)
				}
			})
			subscribe<Comments>('DELETE_AGREEMENT', (data) => {
				if (data.divergence_point_id === selectedPoint.id) {
					updateCommentState(data)
				}
			})
		}
	}, [
		addCommentState,
		journeyId,
		selectedPoint,
		subscribe,
		deleteCommentState,
		updateCommentState
	])

	useEffect(() => {
		if (selectedPoint && !fetchedQuestionsIds.length) {
			fetchQuestionsInfo(selectedPoint.id)
			fetchReferencecsAndLinks(selectedPoint.id)
		}
	}, [selectedPoint, fetchQuestionsInfo, fetchReferencecsAndLinks])

	const [linksOrRefs, setLinksOrRefs] = useState('refs')

	if (isLoading || !selectedPoint || !isSuccess) {
		return <Loading />
	}

	const handleToggleVisibility = async () => {
		toast.closeAll()

		updatePointVisibility(!selectedPoint.visible, {
			onSuccess: () => {
				updatePoint({ ...selectedPoint, visible: !selectedPoint.visible }, true)
				emit('EDIT', {
					...selectedPoint,
					visible: !selectedPoint.visible
				})
				toast({
					title: selectedPoint.visible
						? t('map:points.action.hidden.success')
						: t('map:points.action.visible.success'),
					description: selectedPoint.visible
						? t('map:points.action.state.tooltip-alt')
						: '',
					status: 'success'
				})
			},
			onError: () => {
				toast({
					title: t('errors:request.error.title'),
					status: 'error'
				})
			}
		})
	}

	return (
		<DivergenceProvider value={{ isAnonym: data.incognito_mode }}>
			{selectedPoint && (
				<DivergenceSocketContainer pointId={selectedPoint.id} />
			)}
			<ModalContent h='90%' overflow='hidden' p={0}>
				<FullscreenBox.FullscreenButton
					position='absolute'
					top={2}
					right={14}
					color='gray.400'
				/>
				{!isMobile && hasPrivileges && (
					<>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label={
									selectedPoint.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')
								}
								size='sm'
								position='absolute'
								top={2}
								right='102px'
								icon={selectedPoint.visible ? <LuEye /> : <LuEyeOff />}
								fontSize='xl'
								variant='ghost'
								color='gray.400'
							/>
							<MenuList>
								<MenuItem borderRadius='md' onClick={handleToggleVisibility}>
									{selectedPoint.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')}
								</MenuItem>
							</MenuList>
						</Menu>
					</>
				)}

				<ModalCloseButton
					color='gray.400'
					_hover={{ bg: 'gray.100' }}
					zIndex={9999}
				/>

				{isMobile && journeyId && <MobileLayout journeyId={journeyId} />}
				{!isMobile && (
					<DesktopLayout
						leftContent={<LeftDivergenceView setLinksOrRefs={setLinksOrRefs} />}
						rightContent={
							<RightDivergenceView
								linksOrRefs={linksOrRefs}
								journeyId={journeyId || ''}
							/>
						}
					/>
				)}
			</ModalContent>
		</DivergenceProvider>
	)
}

const DivergenceSocketContainer = ({ pointId }: { pointId: string }) => {
	const channel = '/point/divergence-point/incognito-mode'

	const queryClient = useQueryClient()
	const { subscribe } = useSocket(channel)
	const setISAnonym = useDivergenceStore((state) => state.setISAnonym)

	const updateCacheData = (isAnonym: boolean) => {
		const query = queryClient.getQueryCache().find(['divergencePoint', pointId])
		if (query) {
			query.setData((point: any) => {
				point.incognito_mode = isAnonym
				return point
			})
		}
	}

	useEffect(() => {
		subscribe<{ id: string; incognito_mode: boolean }>('EDIT', (data) => {
			if (data.id === pointId) {
				setISAnonym(data.incognito_mode)
				updateCacheData(data.incognito_mode)
			}
		})
	}, [pointId])

	return null
}

const DesktopLayout: React.FC<{
	leftContent: React.ReactElement
	rightContent: React.ReactElement
}> = ({ leftContent, rightContent }) => {
	return (
		<Flex w='full' h='100%' alignItems='stretch'>
			<Flex w='40%'>{leftContent}</Flex>
			<Flex w='60%'>{rightContent}</Flex>
		</Flex>
	)
}

const MobileLayout: React.FC<{ journeyId: string }> = ({ journeyId }) => {
	const [isSidebar, setIsSideBar] = useState(true)
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { questionOpenedIndex, questions, point, summaryQuestionIndex } =
		useCommentsStore()
	const [refsOrLinks, setRefsOrLinks] = useState('refs')
	const { questionId } = useParams<{ questionId: string | undefined }>()
	const hasQuestionOpened = questionOpenedIndex > -1
	const [firstRender, setFirstRender] = useState(true)

	let countQuestions = [...questions]

	if (point?.introduction) {
		countQuestions = [{ id: '0', comments: [], count: 0 }, ...questions]
	}

	if (!hasQuestionOpened && isSidebar && questionOpenedIndex !== -2) {
		return (
			<LeftDivergenceViewMobile
				isAbout={true}
				setIsSideBar={() => setIsSideBar(true)}
				setNotIsSideBar={() => setIsSideBar(false)}
				setRefs={() => setRefsOrLinks('refs')}
				setLinks={() => setRefsOrLinks('links')}
			/>
		)
	} else if (!hasQuestionOpened && !isSidebar && questionOpenedIndex !== -2) {
		return (
			<AboutToolRightViewMobile
				divergencePointId={selectedPoint?.id || ''}
				linksOrRefs={refsOrLinks}
				setIsSideBar={() => setIsSideBar(true)}
			/>
		)
	} else if (hasQuestionOpened && isSidebar && questionId && firstRender) {
		if (point) {
			return (
				<ChatMobile
					point={point}
					questions={countQuestions[questionOpenedIndex]}
					questionOpenedIndex={questionOpenedIndex}
					setIsSideBar={() => setIsSideBar(true)}
					firstRender={firstRender}
					setRender={() => setFirstRender(false)}
					journeyId={journeyId}
				/>
			)
		}
	} else if (!isSidebar && questionOpenedIndex === -2) {
		if (point) {
			return (
				<QuestionAnalysis
					point={point}
					questionOpenedIndex={questionOpenedIndex}
					questions={questions[summaryQuestionIndex]}
					analysisQuestionIndex={summaryQuestionIndex}
					setIsSideBar={() => setIsSideBar(true)}
				/>
			)
		}
	} else if (isSidebar && questionOpenedIndex === -2) {
		return (
			<LeftDivergenceViewMobile
				isSummary={true}
				isAbout={false}
				setIsSideBar={() => setIsSideBar(true)}
				setNotIsSideBar={() => setIsSideBar(false)}
				setRefs={() => setRefsOrLinks('refs')}
				setLinks={() => setRefsOrLinks('links')}
			/>
		)
	} else if (hasQuestionOpened && isSidebar) {
		return (
			<LeftDivergenceViewMobile
				isAbout={false}
				setIsSideBar={() => setIsSideBar(true)}
				setNotIsSideBar={() => setIsSideBar(false)}
				setRefs={() => setRefsOrLinks('refs')}
				setLinks={() => setRefsOrLinks('links')}
			/>
		)
	} else if (hasQuestionOpened && !isSidebar) {
		if (point) {
			return (
				<ChatMobile
					point={point}
					questions={countQuestions[questionOpenedIndex]}
					questionOpenedIndex={questionOpenedIndex}
					setIsSideBar={() => setIsSideBar(true)}
					journeyId={journeyId}
				/>
			)
		}
	}
	return null
}
