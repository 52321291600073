import React, { createContext, useContext, useState } from 'react'

interface GlobalTenantContextProps {
	children: (children: string) => React.ReactNode
}

interface TenantContextType {
	tenant: string
	setTenant: (tenant: string) => void
	isTenantDefault: (tenant: string) => boolean
}

const TENANT_REGEX = /(?:dev\.|stg\.)?(.*).strateegia.digital/
const DEFAULT_TENANT_NAME = 'DEFAULT'
const ALLOWED_TENANTS: { [key: string]: boolean } = {
	SINERGIA: true,
	ANIMA: true,
	INSTITUTOANIMA: true,
	TDS: true,
	FDC: true,
	MAGALU: true,
	MGC: true,
	CIADETALENTOS: true,
	JNJ: true,
	UMUARAMA2050: true,
	EQUATORIAL: true,
	RVDIGITAL: true,
	PCR: true,
	LUMINA: true,
	CGEE: true,
	BATERIASMOURA: true,
	SAEB: true,
	SEBRAEPB: true,
	CHILDHOOD: true,
	FARIASBRITO: true,
	LUMOSACADEMY: true,
	MITSLOANREVIEW: true,
	AMBEV: true,
	FUNDACAOITAU: true,
	PORTOMARINHO: true,
	AMIL: true,
	DEEPESG: true,
	HGS: true,
	GOOGLECLOUD: true,
	SINAENCO: true,
	BB: true,
	TRESCORACOES: true,
	PORTODIGITAL: true,
	INOVABRA: true
}

const TenantContext = createContext<TenantContextType | undefined>(undefined)

export const useTenant = () => {
	const context = useContext(TenantContext)
	if (!context) {
		throw new Error('useTenant must be used within a TenantProvider')
	}
	return context
}

const getTenantFromUrl = (): string => {
	const hostname = window.location.hostname
	const match = hostname.match(TENANT_REGEX)
	if (match && match.length > 1) {
		const tenantName = match[1].split('.')[0].toUpperCase()
		return ALLOWED_TENANTS[tenantName] ? tenantName : DEFAULT_TENANT_NAME
	}
	return DEFAULT_TENANT_NAME
}

const getTenantFromVar = () => {
	const ACTIVE_TENANT: string =
		import.meta.env.VITE_ACTIVE_TENANT?.toUpperCase()

	return ACTIVE_TENANT && ALLOWED_TENANTS[ACTIVE_TENANT] ? ACTIVE_TENANT : ''
}

const GlobalTenantContext: React.FC<GlobalTenantContextProps> = ({
	children
}) => {
	const getTenant = getTenantFromVar() ? getTenantFromVar() : getTenantFromUrl()

	const [tenant, setTenant] = useState<string>(getTenant)

	const isTenantDefault = (tenant: string): boolean => {
		return tenant === DEFAULT_TENANT_NAME
	}

	return (
		<TenantContext.Provider value={{ tenant, setTenant, isTenantDefault }}>
			{children(tenant)}
		</TenantContext.Provider>
	)
}

export default GlobalTenantContext
