import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useParams } from 'react-router-dom'

import {
	Box,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	UseDisclosureReturn,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { FullscreenBox } from 'modules/common/components'
import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStNotice } from 'modules/common/components/icons'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'

import { useMapStore } from '../../map-canvas/store'
import { pointChannel } from '../../point/point_channel'

type Props = {
	disclosure: UseDisclosureReturn
	body?: ReactNode
	footer?: ReactNode
}

export const NoticePointModal: FC<Props> = ({ disclosure, body, footer }) => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { id: journeyId = '', mapId = '' } = useParams<{
		questionId: string | undefined
		id: string | undefined
		mapId: string | undefined
	}>()
	const {
		state: { selectedPoint },
		actions: { updatePoint }
	} = useMapStore()
	const { t } = useTranslation()

	const toast = useToast()
	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'notice-point',
		pointId: selectedPoint?.id || ''
	})
	const hasPrivileges = useJourneyPrivileges()

	const handleToggleVisibility = async () => {
		if (selectedPoint) {
			toast.closeAll()

			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint(
						{ ...selectedPoint, visible: !selectedPoint.visible },
						true
					)
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					toast({
						title: selectedPoint.visible
							? t('map:points.action.hidden.success')
							: t('map:points.action.visible.success'),
						description: selectedPoint.visible
							? t('map:points.action.state.tooltip-alt')
							: '',
						status: 'success'
					})
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<FullscreenBox
			{...disclosure}
			size={{ base: '3xl' }}
			scrollBehavior={isMobile ? 'outside' : 'inside'}
		>
			<ModalOverlay />
			<ModalContent maxW={700}>
				{!isMobile && hasPrivileges && selectedPoint?.point_type !== 'ADD' && (
					<>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label={
									selectedPoint?.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')
								}
								size='sm'
								position='absolute'
								top={2}
								right='102px'
								icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
								fontSize='xl'
								variant='ghost'
								color='gray.400'
								mt={2}
							/>
							<MenuList>
								<MenuItem borderRadius='md' onClick={handleToggleVisibility}>
									{selectedPoint?.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')}
								</MenuItem>
							</MenuList>
						</Menu>
					</>
				)}
				<FullscreenBox.FullscreenButton
					position='absolute'
					top={4}
					right='61px'
					color='gray.400'
				/>
				<ModalCloseButton mr={2} top={4} color='gray.400' />
				<ModalHeader px={isMobile ? 4 : 8} mt={4}>
					<Stack direction='row' alignItems='center'>
						{selectedPoint && (
							<SelectColor
								color='red.400'
								visible={selectedPoint.visible}
								Icon={IconStNotice}
							/>
						)}
						<Box>
							<Text fontWeight='semibold' fontSize={['lg', 'xl']}>
								{t('map:points:notice:title')}
							</Text>
							<Text
								color='gray.400'
								fontWeight='medium'
								fontSize={['sm', 'md']}
							>
								{t('map:points:notice:text')}
							</Text>
						</Box>
					</Stack>
				</ModalHeader>
				<ModalBody px={isMobile ? 4 : 8}>{body}</ModalBody>
				<ModalFooter px={8}>{footer}</ModalFooter>
			</ModalContent>
		</FullscreenBox>
	)
}
